import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Meta from 'components/meta'
import Layout from 'components/layout'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'en'

const EnServicesIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, ServicesPageForm)
  const page = node.pages.services.en

  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <section style={{ backgroundColor: `#ECE4DF` }}>
        <div id="services">
          <header id="services" className="masthead">
            <div className="container d-flex align-items-center">
              <div className="mx-auto text-center">
                <div className="" style={{ marginTop: `10em` }}>
                  <h2
                    className="mt-2 desktop-only"
                    style={{ color: `#ffffff` }}
                  >
                    {page.title}
                  </h2>
                </div>
              </div>
            </div>
          </header>

          <div className="services-body">
            <section style={{ backgroundColor: `#ece4df !important` }}>
              <div className="container">
                <h3 className="mobile-only text-center">{page.title}</h3>
                <h2>{page.coaching.text}</h2>
                <ul style={{ textAlign: `left` }}>
                  {page.coaching.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
                <h2>{page.training.text}</h2>
                <p>{page.training.body}</p>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section
        id="footer2"
        className="footer2-section desktop-only"
        style={{ paddingBottom: `1rem` }}
      >
        <div
          className="footer-contact desktop-only container"
          style={{ marginBottom: `3rem` }}
        >
          <h2>Contact:</h2>
          <p>
            {page.contact.name}, {page.contact.address} <br />
            Tel: {page.contact.telephone} | Email:{' '}
            <a href={`mailto:${page.contact.mail}`}>{page.contact.mail}</a>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default EnServicesIndex

const ServicesPageForm = {
  fields: [
    {
      name: `rawJson.pages.services.${lang}.site_title`,
      component: 'text',
      label: 'Site Title',
      required: true,
    },
    {
      name: `rawJson.pages.services.${lang}.title`,
      component: 'text',
      label: 'Title',
      required: true,
    },
    {
      label: 'Coaching Offer',
      name: `rawJson.pages.services.${lang}.coaching`,
      description: 'Coaching Offer',
      component: 'group',
      fields: [
        {
          name: 'text',
          component: 'text',
          label: 'Title',
          required: true,
        },
        {
          component: 'group-list',
          name: 'points',
          label: 'Points',
          required: true,
          fields: [
            {
              label: 'Text',
              name: 'text',
              component: 'text',
            },
          ],
          itemProps: item => ({
            label: item.text ? item.text : 'Bullet Point',
            key: item,
          }),
        },
      ],
    },
    {
      label: 'Training Offer',
      name: `rawJson.pages.services.${lang}.training`,
      description: 'Teaching Offer',
      component: 'group',
      fields: [
        {
          name: 'text',
          component: 'text',
          label: 'Title',
          required: true,
        },
        {
          name: 'body',
          component: 'textarea',
          label: 'Text',
          description: 'Enter the text here',
          required: true,
        },
      ],
    },
    {
      name: `rawJson.pages.services.${lang}.contact`,
      component: 'group',
      label: 'Contact',
      description: 'Contact info',
      fields: [
        {
          name: 'name',
          component: 'text',
          label: 'Name',
          required: true,
        },
        {
          name: 'address',
          component: 'text',
          label: 'Address',
          required: true,
        },
        {
          name: 'telephone',
          component: 'text',
          label: 'Telephone Number',
          required: true,
        },
        {
          name: 'mail',
          component: 'text',
          label: 'Mail',
          required: true,
        },
      ],
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        services {
          en {
            site_title
            title
            coaching {
              text
              points {
                text
              }
            }
            training {
              text
              body
            }
            contact {
              name
              address
              telephone
              mail
            }
          }
        }
      }
      rawJson
      fileRelativePath
    }
  }
`
